import React from "react";
import Helmet from "react-helmet";

import PropTypes from "prop-types";

import config from "utils/siteConfig";
import T from "types/index";

type Props = {
  items: T.Standard.BreadcrumbLink[];
};

type DSListItem = {
  "@type": "ListItem";
  position: number;
  name: string;
  item: string;
};

const StructuredBreadcrumb = ({ items }: Props) => {
  const { siteUrl } = config;
  const itemListElements: DSListItem[] = items.map((item, index) => ({
    "@type": "ListItem",
    position: index,
    name: item.name,
    item: `${siteUrl}${item.to}`,
  }));

  const schema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: itemListElements,
  };

  return (
    <>
      <Helmet defer={false}>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    </>
  );
};

StructuredBreadcrumb.propTypes = {
  items: PropTypes.array.isRequired,
};

export default StructuredBreadcrumb;
