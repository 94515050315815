import React from "react";
import CharlesMeta from "components/common/meta/CharlesMeta";
import { Link } from "gatsby";
import { headingToAnchor, stripHtml } from "templates/Blog/utils";
import "./styles.sass"

class Summary extends React.Component {
  render() {
    const { post } = this.props;
    const headings = post.blocks
      ? post.blocks.filter(
          (block) => block.name === "core/heading" && block.attributes.level === 2
        )
      : [];
    return (
      <div className="summary-div">
        <div className="summary-title h2">Sommaire</div>
        <ol>
          {headings.map((heading, index) => (
            <li className="blog-summary-item" key={`summary_heading_${index}`}>
              <Link to={`#${headingToAnchor(heading)}`}>
                {stripHtml(heading.attributes.content)}
              </Link>
            </li>
          ))}
        </ol>
      </div>
    );
  }
}

export default Summary;
