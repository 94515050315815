import React from 'react';
import Helmet from 'react-helmet';
import structuredData from './structuredData.json'

const StructuredBlogPage = ({
  slug
}) => {
  const schema = structuredData[slug]

  return schema ? (
    <Helmet defer={false}>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  ) : null;
};

export default StructuredBlogPage;
