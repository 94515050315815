import React from "react";

class ScrollButtonLeft extends React.Component {
  render() {
    return (
      <svg
        width="49"
        height="48"
        viewBox="0 0 49 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          r="23"
          transform="matrix(-1 0 0 1 24.0645 24)"
          stroke="black"
          stroke-width="2"
        />
        <path
          d="M26.0645 18L20.4076 23.6569L26.0645 29.3137"
          stroke="black"
          stroke-width="2"
        />
      </svg>
    );
  }
}

export default ScrollButtonLeft;
