import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import {
  postSlug,
  postParentCategory,
  categorySlug,
} from "templates/Blog/utils/slugs";

class PostCardCarousel extends React.Component {
  render() {
    const { post, showCategory } = this.props;
    const postLink = postSlug(post);
    const mainCategory = postParentCategory(post);
    const array = Object.entries(post);
    return (
      <div className="carousel-blog-post-list-item" id={array[5][1]}>
        <div className="carousel-blog-post-list-item-image">
          <GatsbyImage
            image={
              post.featuredImage.node.localFile.childImageSharp
                .gatsbyImageData
            }
            className="img-fluid img-blog"
            alt={post.title}
          />
        </div>
        {showCategory && mainCategory && (
          <Link to={categorySlug(mainCategory)} className="category-link">
            {mainCategory.name}
          </Link>
        )}
        <div className="blog-post-list-item-title truncate-text-3">
          <Link className="unstyled-link" to={postLink}>
            {post.title}
          </Link>
        </div>
        <div
          className="carousel-blog-post-list-item-excerpt truncate-text-3"
          dangerouslySetInnerHTML={{ __html: post.excerpt }}
        />
      </div>
    );
  }
}

export default PostCardCarousel;
