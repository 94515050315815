import _ from 'lodash';

const authorMap = {
  "Tristan Chevrier": {
    pageSlug: "/blog/author/tristan-chevrier/",
    pageUri: "/tristan-chevrier/",
    role: "Rédigé par",
    description: "Tristan Chevrier – Auteur Charles. Intéressé par les problématiques liées à la sexualité et au bien-être, il travaille en coordination avec l’équipe médicale de Charles.",
    image: 'pp_default.png',
    alt: 'pp-charles.co',
    title: 'Rédacteur chez Charles.co',
    slug: 'tristanc',
    name: "Tristan Chevrier"
  },
  "Gilbert Bou Jaoudé": {
    pageSlug: "/blog/author/dr-gilbert-bou-jaoude/",
    pageUri: "/dr-gilbert-bou-jaoude/",
    role: "Révisé par le Docteur",
    description: "Docteur Gilbert Bou Jaoudé, médecin sexologue, ancien président de la Société Francophone de Médecine Sexuelle (SFMS), coordinateur scientifique de la Plateforme Charles",
    image: 'pp_gilbert.png',
    alt: 'pp-gilbert-bou-jaoude',
    title: 'Docteur Gilbert Bou Jaoudé',
    slug: 'gilbert',
    name: "Gilbert Bou Jaoudé"
  },
  "Claire Alquier": {
    pageSlug: "/blog/author/claire-alquier/",
    pageUri: "/claire-alquier/",
    role: "Rédigé par",
    description: "Claire Alquier – sexologue et thérapeute de couple, Claire Alquier est diplômée en Sexologie et Santé publique par l’Institut de sexologie de Jacques Waynberg",
    image: 'pp_default.png',
    alt: 'pp charles.co',
    title: 'Claire Alquier',
    slug: 'dianed',
    name: "Claire Alquier"
  },
  "Rémi Lombard": {
    pageSlug: "/blog/author/dr-remi-lombard/",
    pageUri: "/dr-remi-lombard/",
    role: "Révisé par le Docteur",
    description: "",
    image: 'pp_remi.png',
    alt: 'Profile Dr. Rémi Lombard',
    title: 'Docteur Rémi Lombard',
    slug: 'dr-remi-lombard',
    name: "Rémi Lombard"
  },
  "Assya Ouchene": {
    pageSlug: "/blog/author/assya-ouchene/",
    pageUri: "/assya-ouchene/",
    role: "Rédigé par",
    description: "Assya Ouchene - Rédactrice en chef. Elle veille à ce que chaque article bénéficie d'une bonne visibilité afin que tout le monde puisse facilement accéder aux conseils de nos patriciens.",
    image: 'pp_default.png',
    alt: 'Assya Ouchene est rédactrice en chef chez charles.co',
    title: 'Assya Ouchene',
    slug: 'assya-ouchene',
    name: "Assya Ouchene"
  },
  "Jessica Bouchikhi": {
    pageSlug: "/blog/author/jessica-bouchikhi/",
    pageUri: "/jessica-bouchikhi/",
    role: "Rédigé par",
    description: "Jessica Bouchikhi - Responsable Rédaction. Passionnée par les mots, elle rédige des articles permettant d’informer et de sensibiliser aux sujets de santé sexuelle et de problèmes chroniques.",
    image: 'pp_default.png',
    alt: 'Jessica Bouchikhi – Responsable Rédaction Charles.co',
    title: 'Jessica Bouchikhi',
    slug: 'jessica-bouchikhi',
    name: "Jessica Bouchikhi"
  },
  "Thomas Killian": {
    pageSlug: "/blog/author/dr-thomas-killian/",
    pageUri: "/dr-thomas-killian/",
    role: "Révisé par le Docteur",
    description: "",
    image: 'pp_thomas.jpg',
    alt: 'Le Thomas Killian est spécialisé en nutrition',
    title: 'Thomas Killian',
    slug: 'dr-thomas-killian',
    name: "Thomas Killian"
  },
  "Pierre Schneider": {
    pageSlug: "/blog/author/dr-pierre-schneider/",
    pageUri: "/dr-pierre-schneider/",
    role: "Révisé par le Docteur",
    description: "",
    image: 'pp_pierre.jpg',
    alt: 'Pierre Schneider - dermatologue et vénérologue, Membre de la société française de la dermatologie',
    title: 'Pierre Schneider',
    slug: 'dr-pierre-schneider',
    name: "Pierre Schneider"
  },
  "François Kraus": {
    pageSlug: "/blog/author/francois-kraus-expert-sexualite-ifop/",
    pageUri: "/francois-kraus-expert-sexualite-ifop/",
    role: "Révisé par",
    description: "François Kraus est un expert en genre, sexualité et santé sexuelle à l'IFOP. Il mène des études et observation en étroite collaboration avec Charles.co afin d'apporter des réponses sur les questions des troubles sexuels.",
    image: 'pp_francois-kraus.jpg',
    alt: 'francois-kraus',
    title: "François Kraus - Expert Genre, Sexualités et Santé sexuelle à l'IFOP",
    slug: 'francois-kraus',
    name: "François Kraus"
  }
  // "Olivier Algoud": {
  //   pageSlug: "",
  //   pageUri: "",
  //   role: "Rédigé par",
  //   image: 'pp_default.png',
  //   slug: 'olivier',
  //   name: "Olivier Algoud"
  // },
};

const isEmpty = (value) => {
  if (value == null || value === undefined) {
    return true;
  }
  if (value && value.length) return false;
  return true;
}

const findMedicalAdvisorFor = (post) => {
  const categorySlug = isEmpty(post.categories.nodes) ? undefined : post.categories.nodes[0].slug
  let doctor;
  switch (categorySlug) {
    case 'sommeil':
      doctor = 'Rémi Lombard'
      break;
    case 'cheveux':
      doctor = 'Pierre Schneider'
      break;
    case 'digestion':
      doctor = 'Thomas Killian'
      break;
    case 'etudes-et-sondages':
      doctor = 'François Kraus'
      break;
    default:
      doctor = 'Gilbert Bou Jaoudé'
      break;
  }
  return authorMap[doctor]
}

export default authorMap
export { findMedicalAdvisorFor }
