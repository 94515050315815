import React from "react";
import Star from "images/dynamicImages/Star";
import "./styles.sass";

type Props = {
  rating: number;
  maxRating: number;
  starSize?: number;
};

const StarRating = ({ rating, maxRating, starSize = 40 }: Props) => {
  const gap = starSize > 30 ? 4 : 2;
  let rate = rating;
  const stars = Array.from({ length: maxRating }, (_, i) => {
    const starCompletion = Math.min(1, rate);
    rate = Math.max(0, rate - 1);

    return <Star size={starSize} starCompletion={starCompletion} key={i} />;
  });

  return <div style={{ gap: gap}} className="stars-rating-row">{stars}</div>;
};

export default StarRating;
