import React from "react";
import PostCardCarousel from "./PostCardCarousel";
import cx from "classnames"
import "templates/Blog/common/PostList/common/styles.sass";

import ScrollButtonRight from 'templates/Blog/Home/ScrollButtonRight';
import ScrollButtonLeft from 'templates/Blog/Home/ScrollButtonLeft';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.cardWidth = 5,
    this.carouselSectionRef = React.createRef();
  }

  componentDidMount() {
    if (screen.width > 992) {
      this.cardWidth = 418;
    } else {
      this.cardWidth = 303;
    }
  }

  render() {
    const { title, posts, showCategory, className } = this.props;
    const scrollSection = (scrollOffset) => {
      this.carouselSectionRef.current.scrollLeft += scrollOffset;
    };
    return (
      <>
        <div className={cx("d-flex justify-content-center justify-content-md-between align-items-center", className)}>
          <h2 className="my-0">{title}</h2>
          <div className="scroll-button-div-l">
            <div
              className="scroll-button-left"
              onClick={() => scrollSection(-this.cardWidth)}
            >
              <ScrollButtonLeft width={48} height={48} />
            </div>
            <div className="stepper-div">
              {posts.map((x, index) => (
                <div className="stepper" key={index} />
              ))}
            </div>
            <div
              className="scroll-button-right"
              onClick={() => scrollSection(this.cardWidth)}
            >
              <ScrollButtonRight width={48} height={48} />
            </div>
          </div>
        </div>
        <div className="blog-section-list">
          <div className="blog-post-list" ref={this.carouselSectionRef}>
            <div className="blog-section-details">
              <div className="carousel-blog-post-list-container">
                {posts.map((post, index) => (
                  <div key={`blog_post_list_item_${index}`} id={post.id}>
                    <PostCardCarousel showCategory={showCategory} post={post} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Carousel;
