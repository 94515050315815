import React from "react";

type Props = {
  size?: number;
  // value is in percentage e.g. 0.25, 0.5, 0.75, 1
  starCompletion?: number;
};

const Star = ({ size = 30, starCompletion = 1 }: Props) => {
  // round to nearest quarter
  const roundNearQtr = Number((Math.round(starCompletion * 4) / 4).toFixed(2));
  const completion = roundNearQtr * 40;
  return (
    <svg
      width={size}
      height={size}
      style={{"borderRadius": "4px"}}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" fill="#E1C34180" />
      <rect width={completion} height="40" fill="#E1C341" />
      <path
        d="M17.3505 4.9885C18.4785 2.86476 21.5215 2.86477 22.6495 4.98851L25.4493 10.2601C25.883 11.0765 26.6688 11.6475 27.5792 11.8076L33.458 12.8414C35.8264 13.2579 36.7668 16.152 35.0955 17.881L30.9471 22.1728C30.3046 22.8375 30.0045 23.7613 30.1336 24.6767L30.967 30.5872C31.3028 32.9684 28.8409 34.757 26.6801 33.7019L21.3164 31.0828C20.4857 30.6771 19.5143 30.6771 18.6836 31.0828L13.3199 33.7019C11.1591 34.757 8.69722 32.9684 9.03298 30.5872L9.86643 24.6767C9.99551 23.7613 9.69535 22.8375 9.05287 22.1728L4.90448 17.881C3.23324 16.152 4.1736 13.2579 6.54196 12.8414L12.4208 11.8076C13.3312 11.6475 14.117 11.0765 14.5507 10.2601L17.3505 4.9885Z"
        fill="white"
        stroke="#000000"
        strokeWidth="1"
      />
    </svg>
  );
};

export default Star;
