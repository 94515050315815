import React from "react";

class ScrollButtonRight extends React.Component {
  render() {
    return (
      <svg
        width="48"
        height="48"
        viewBox="0 0 48 48"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="24" cy="24" r="23" stroke="black" stroke-width="2" />
        <path
          d="M22 18L27.6569 23.6569L22 29.3137"
          stroke="black"
          stroke-width="2"
        />
      </svg>
    );
  }
}

export default ScrollButtonRight;
