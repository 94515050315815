import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

type Props = {
  data: QA[]
}
type QA = {
  question: string
  answer: string
}

const StructuredFAQ = ({
  data
}: Props) => {

  if (data.length == 0) {
    return null;
  }

  // TODO Type for structured data
  let questionsAndAnwsers: any = []

  data.forEach((row) => {
    questionsAndAnwsers.push({
      "@type": "Question",
      "name": row.question,
      "acceptedAnswer": {
        "@type": "Answer",
        "text": row.answer
      }
    })
  })

  const schema = {
    "@context": "https://schema.org",
    "@type": "FAQPage",
    "mainEntity": questionsAndAnwsers
  }

  return (
    <>
      <Helmet defer={false}>
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      </Helmet>
    </>
  );
};

export default StructuredFAQ;
