import React from "react";
import { Link } from "gatsby";
import cx from "classnames";
import Arrow from "images/icons/arrow-right.svg";
import StructuredBreadcrumb from "components/common/meta/StructuredBreadcrumb";
import {
  postParentCategory,
  postSlug,
  categorySlug,
  pageSlug,
  blogSlug,
  homeSlug,
  tagSlug,
} from "templates/Blog/utils/slugs";

import {
  pathologiesDaughterSlug,
  pathologiesGrandMotherSlug,
  pathologiesMotherSlug,
} from "templates/Pathologies/utils/slugs";
import T from "types/index";
import {
  annuarySlug,
  departmentSlug,
  citySlug,
  isCitySameAsDepartment,
} from "templates/Annuary/utils/slugs";
import { get } from "utils/lodashExtracts";
import "./styles.sass";
import LinkOrAOrText from "components/common/links/LinkOrAOrText";

type Props = {
  links: T.Standard.BreadcrumbLink[];
};

const Breadcrumb = ({ links }: Props) => (
  <>
    <StructuredBreadcrumb items={links} />
    <div className="blog-breadcrumb">
      {links.map(({ to, name }, index) => {
        const isFirstElement: boolean = index == 0;
        const isLastElement: boolean = index == links.length - 1;
        return (
          <span
            className="blog-breadcrumb-link"
            key={`blog_breadcrumb_link_${index}`}
          >
            <Arrow
              className={cx({
                "d-none": isFirstElement,
              })}
            />
            <LinkOrAOrText
              to={isLastElement ? undefined : to}
              anchor={name}
              className={cx({
                "last-item-blog-breadcrumb": isLastElement,
              })}
            />
          </span>
        );
      })}
    </div>
  </>
);

export const postBreadcrumbs = (post: any) =>
  categoryBreadcrumbs(postParentCategory(post)).concat({
    to: postSlug(post),
    name: post.title,
  });

export const authorBreadcrumbs = (author: any) =>
  blogBreadcrumbs().concat({
    to: author.uri,
    name: author.name,
  });

export const categoryBreadcrumbs = (category: any) =>
  blogBreadcrumbs().concat({
    to: categorySlug(category),
    name: category.name,
  });

export const tagBreadcrumbs = (tag: any) =>
  blogBreadcrumbs().concat({
    to: tagSlug(tag),
    name: tag.name,
  });

export const blogBreadcrumbs = () => [
  {
    to: homeSlug(),
    name: "Accueil",
  },
  {
    to: blogSlug(),
    name: "Blog",
  },
];

export const pageBreadcrumbs = () => [
  {
    to: homeSlug(),
    name: "Accueil",
  },
];

export const pathologiesGrandMotherBreadcrumbs = () => [
  {
    to: homeSlug(),
    name: "Accueil",
  },
  {
    to: pathologiesGrandMotherSlug(),
    name: "Toutes nos pathologies",
  },
];

export const pathologiesMotherBreadcrumbs = (category: T.Charles.Category) =>
  pathologiesGrandMotherBreadcrumbs().concat({
    to: pathologiesMotherSlug(category.slug),
    name: category.name,
  });

export const pathologiesDaughterBreadcrumbs = (
  category: T.Charles.Category,
  pathology: T.Charles.Pathology
) =>
  pathologiesMotherBreadcrumbs(category).concat({
    to: pathologiesDaughterSlug(category.slug, pathology.slug),
    name: pathology.breadcrumb_label,
  });

export const annuaryBreadcrumbs = () => [
  {
    to: homeSlug(),
    name: "Accueil",
  },
  {
    to: annuarySlug(),
    name: "Annuaire",
  },
];

export const annuaryDepartmentBreadcrumbs = (department: T.Charles.Department) =>
  annuaryBreadcrumbs().concat({
    to: departmentSlug(department.slug),
    name: `Sexologues ${department.A_LIEU}`,
  });

export const annuaryCityBreadcrumbs = (city: T.Charles.City) => {
  const department: T.Charles.Department = get(city, "departments[0].data");
  const parentBreadcrumbs = isCitySameAsDepartment(city.slug)
    ? annuaryBreadcrumbs()
    : annuaryDepartmentBreadcrumbs(department);

  return parentBreadcrumbs.concat({
    to: citySlug(department.slug, city.slug),
    name: `Sexologues ${city.A_LIEU}`,
  });
};

export default Breadcrumb;
