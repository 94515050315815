import React from 'react';
import Helmet from 'react-helmet';

import PropTypes from 'prop-types';

const StructuredData = ({
  data
}) => {
  if (data.length == 0) {
    return null;
  }

  return (
    <>
      <Helmet defer={false}>
        <script type="application/ld+json">{data}</script>
      </Helmet>
    </>
  );
};

StructuredData.propTypes = {
  data: PropTypes.string.isRequired,
};

export default StructuredData;
