import React from "react";
import "./styles.sass";
import { Blog } from "types/index";
import { useAirtableBlogCta } from "hooks/useAirtableBlogCtas";
import { useSatismeterScore } from "hooks/useSatismeterScore";
import StarRating from "components/common/StarRating";
import { AirtableMarkdown } from "components/common/utils/AirtableMarkdown";
import { AirtableImages } from "components/common/utils/AirtableImage";
import StyledLink from "components/common/utils/StyledLink";

type Props = {
  post: Blog.Post;
};

const FixedBottomCta = (props: Props) => {
  const { post } = props;
  const blogCta = useAirtableBlogCta(post);
  const { ratingValue, maxRating } = useSatismeterScore();

  if (blogCta) {
    return (
      <div className="fixed-bottom-cta">
        <div className="fixed-bottom-cta-image">
          <div className="fixed-bottom-cta-image-image">
          <AirtableImages images={blogCta.data.image} />
          </div>
        </div>
        <div className="fixed-bottom-cta-title">
          {blogCta.data.sticky_banner_title}
        </div>
        <div className="fixed-bottom-cta-bullets">
          <AirtableMarkdown markdown={blogCta.data.sticky_banner_content} />
        </div>
        <div className="fixed-bottom-cta-ratings">
          <div className="fixed-bottom-cta-ratings-grade">
            <div className="grade">
              {`${ratingValue}`.replace(".", ",")}/{maxRating}
            </div>
          </div>
          <StarRating rating={ratingValue} maxRating={maxRating} starSize={20} />
        </div>
        <div className="fixed-bottom-cta-cta">
          <StyledLink path={blogCta.data.cta_url} variant="black" className="text-white">
              {blogCta.data.sticky_banner_cta_label}
          </StyledLink>
        </div>
      </div>
    );
  } else {
    return null
  }
};

export default FixedBottomCta;
