import React from "react";
import "./styles.sass";
import { Blog } from "types/index";
import { useAirtableBlogCta } from "hooks/useAirtableBlogCtas";
import {Link} from "gatsby";
import {Button} from "react-bootstrap";

type Props = {
  post: Blog.Post;
};

const HeadingCta = (props: Props) => {
  const { post } = props;
  const blogCta = useAirtableBlogCta(post);

  if (blogCta) {
    return (
      <div className="blog-post-consultation-block mb-4">
        <div className="blog-post-consultation-block-title mb-4">
          <p>{blogCta.data.heading_bloc_title}</p>
        </div>
        <div className="blog-post-consultation-block-body flex-column">
          <Link to={blogCta.data.cta_url} rel="nofollow">
            <Button variant="black" className="text-white w-auto px-4">{blogCta.data.heading_bloc_cta_label}</Button>
          </Link>
        </div>
      </div>
    );
  } else {
    return null
  }
};

export default HeadingCta;
