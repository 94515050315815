import React from 'react'

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon
} from 'react-share'

import { useLocation } from "@reach/router"
import config from 'utils/siteConfig';

import './Share.sass';

const ShareButtons = ({title, uri}) => {
  const {
    siteUrl,
    facebookAppId
  } = config

  const pathname = useLocation().pathname
  const url = `${siteUrl}${pathname}`

  return (
    <div className="share-links">
      <div className="share-links-title">
        Partagez l'article sur:
      </div>
      <div className="share-links-buttons">
        <FacebookShareButton url={url} quote={title} >
          <FacebookIcon size={40} round={true}/>
        </FacebookShareButton>
        <FacebookMessengerShareButton url={url} appId={facebookAppId} >
          <FacebookMessengerIcon size={40} round={true}/>
        </FacebookMessengerShareButton>
        <TwitterShareButton url={url} title={title} >
          <TwitterIcon size={40} round={true} />
        </TwitterShareButton>
        <WhatsappShareButton url={url} title={title}>
          <WhatsappIcon size={40} round={true}/>
        </WhatsappShareButton>
      </div>
    </div>
  )

}
export default ShareButtons
