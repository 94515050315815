import React from "react";
import "./styles.sass";
import { Link } from "gatsby";
import { tagSlug } from "templates/Blog/utils/slugs";


class CategoryTag extends React.Component {
  

  render() {
    const { tags } = this.props;

    return (
      <div className="category-tag">
        <h3 className="category-tag-title">Consultez d’autres articles par catégories :</h3>
        <div className="d-flex flex-wrap">
          {tags && tags.map(tag => 
            <Link key={tag.id} to={tagSlug(tag)} className="category-tag-item">{tag.name}</Link>
          )}
        </div>
      </div>
    );
  }
}

export default CategoryTag;
