import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import T from "types/index";
import { first, isNil } from "utils/lodashExtracts";

const AirtableImage = ({
  className,
  image,
}: {
  className?: string;
  image: T.Charles.ImageRecord;
}) => {
  if (image != null) {
    const attachment: T.Standard.AttachmentNode = image.data.image;
    const localFile: T.Standard.LocalImageNode = first(attachment.localFiles);
    return isNil(localFile.childImageSharp) ? (
      <img
        className={className}
        src={localFile.publicURL}
        alt={image.data.alt}
        title={image.data.title}
      />
    ) : (
      <GatsbyImage
        image={localFile.childImageSharp.gatsbyImageData}
        className={className}
        alt={image.data.alt}
        title={image.data.title}
      />
    )
  } else {
    return null;
  }
};

const AirtableImages = ({
  className,
  images,
}: {
  className?: string;
  images: T.Charles.ImageRecord[];
}) => {
  const image = first(images);
  return <AirtableImage className={className} image={image} />;
};

export { AirtableImage, AirtableImages };
